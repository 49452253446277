import React, { useEffect, useRef, useState } from 'react';
import { CameraIcon } from '@heroicons/react/24/outline';
import { maxCameraHeightResolution, maxCameraWidthResolution } from '../Camera/data';
import { getCameraHeight, getCameraWidth } from '../Camera/functions';

export default function PackingCamera({ photoUpload }) {
    const canvasRef = useRef(null);
    const videoRef = useRef(null);

    const [videoStream, setVideoStream] = useState(null);

    useEffect(() => {
        startCamera();
    }, []);

    async function startCamera() {
        if (videoRef?.current && videoRef?.current?.srcObject) {
            const tracks = videoRef?.current?.srcObject?.getTracks();
            tracks.forEach((track) => track.stop());
        }

        try {
            const stream = await navigator.mediaDevices.getUserMedia({
                video: {
                    focusMode: 'continuous', // Ask for continuous autofocus if available
                    width: { ideal: maxCameraWidthResolution },
                    height: { ideal: maxCameraHeightResolution },
                },
            });
            setVideoStream(stream)
            videoRef.current.srcObject = stream;
            videoRef.current.play();
        } catch (err) {
            console.error('Failed to start the camera:', err);
        }
    }

    function handleTakePhoto() {
        const context = canvasRef.current.getContext('2d');
        context.drawImage(videoRef.current, 0, 0, getCameraWidth(videoStream), getCameraHeight(videoStream));
        const dataUri = canvasRef.current.toDataURL('image/png');
        photoUpload({ dataUri });
    }

    if (!videoStream) {
        return null;
    }

    return (
        <>
            <video className='mx-auto' ref={videoRef} width='550' height='300'></video>
            <canvas
                ref={canvasRef}
                style={{ display: 'none' }}
                width={getCameraWidth(videoStream)}
                height={getCameraHeight(videoStream)}
            ></canvas>
            <button
                className='px-2 py-2 my-4 mx-auto bg-green-200 border flex border-green-600 rounded-md text-green-600 hover:bg-green-600 hover:text-white w-48 justify-center text-lg'
                onClick={handleTakePhoto}
            >
                Take Photo <CameraIcon className='h-7 w-7 ml-2' aria-hidden='true' />
            </button>
        </>
    );
}
