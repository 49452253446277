import React, { useEffect, useState } from 'react';
import LocationsTable from '../components/LocationsTable';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { useAuth } from '../../wms/context/wms-context';
import config from '../../utils/config';
import LoadingSpinner from '../../components/LoadingSpinner';
import ConfirmationModal from '../components/ConfirmationModal';
import { FixedSizeList as List } from 'react-window';
import logError from '../../utils/errorUtil';

// /wms/print-location
export default function WMS_Location() {
    const { currentUser, setCurrentUser } = useAuth();
    const ORIGIN = config.origin;
    const [locationName, setLocationName] = useState('');
    const [allLocations, setAllLocations] = useState(currentUser.locations.locations); // State to hold all locations
    const [filteredLocations, setFilteredLocations] = useState([]); // State to hold filtered locations based on input
    const [showSuggestions, setShowSuggestions] = useState(false); // State to control when to show suggestions
    const [error, setError] = useState(''); // State to hold error message
    const [loading, setLoading] = useState(false);
    const [confirmationModal, setConfirmationModal] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);

    const handleSubmit = (event, selectedLocationName, fromClick = false) => {
        if (event) event.preventDefault();
        setError(''); // Clear error message
        setCurrentPage(1);

        if (fromClick) {
            const location = allLocations.find((location) => location.Name === selectedLocationName);
            if (location) {
                setFilteredLocations((prev) => [...prev, location]);
                setLocationName('');
                return;
            }
        }

        // If the action is from pressing Enter and there are suggestions available
        if (filteringLocations.length > 0) {
            setFilteredLocations((prev) => [...prev, ...filteringLocations]);
            setLocationName('');
            return;
        }

        // If there's no suggestion and the user presses Enter
        if (!selectedLocationName && filteringLocations.length === 0) {
            setLocationName('');
            setError('Location not found');
            return;
        }
    };

    const handleInputChange = (event) => {
        setLocationName(event.target.value);
        setShowSuggestions(true); // Show suggestions when input changes
        setError(''); // Clear error message
    };

    const handleSuggestionClick = (location) => {
        setShowSuggestions(false); // Hide suggestions after selection
        handleSubmit(null, location.Name, true); // Pass true for the fromClick parameter
    };

    // Filter the locations based on the input
    const filteringLocations = allLocations.filter((location) =>
        location?.Name?.toLowerCase().startsWith(locationName.toLowerCase())
    );

    const handlePrintAll = (isCallback) => {
        setLoading(true);
        const dataToSend = {
            authentication: {
                userId: currentUser.authentication.userId,
                userEmail: currentUser.authentication.userEmail,
                userCompany: currentUser.authentication.userCompany || localStorage.getItem('company'),
            },
            locations: filteredLocations.length >= 1 ? filteredLocations : allLocations,
        };
        fetch(`${ORIGIN}/wms/location/print-location`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataToSend),
        })
            .then((res) => res.blob())
            .then((data) => {
                if (filteredLocations.length >= 100 || filteredLocations.length == 0) {
                    // remove 100 locations from the list

                    setConfirmationModal(true);
                }
                // render the blob as pdf in a new tab
                const file = new Blob([data], { type: 'application/pdf' });
                const fileURL = URL.createObjectURL(file);

                fileURL && window.open(fileURL);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                logError(err, 'handlePrintAll');
            });
    };

    const handleRemoval = () => {
        if (filteredLocations.length >= 100) {
            setFilteredLocations((prev) => prev.slice(100));
        } else {
            setFilteredLocations(allLocations.slice(100));
        }
        setConfirmationModal(false);
    };

    useEffect(() => {
        // get all locations and set current user locations /get-locations

        setLoading(true);
        const dataToSend = {
            authentication: {
                userId: currentUser.authentication.userId,
                userEmail: currentUser.authentication.userEmail,
                userCompany: currentUser.authentication.userCompany || localStorage.getItem('company'),
            },
        };

        fetch(`${ORIGIN}/wms/location/get-locations`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataToSend),
        })
            .then((res) => res.json())
            .then((data) => {
                setAllLocations(data.locations);
                // set data to locations.locations
                setCurrentUser((prev) => ({ ...prev, locations: data }));
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                logError(err, 'WMS_Location');
            });
    }, []);

    return (
        <div>
            <ConfirmationModal
                open={confirmationModal}
                setOpen={setConfirmationModal}
                callBack={handleRemoval}
                title={'Remove First 100 Locations'}
                text={
                    'You can only print 100 at a time would you like to remove the first 100 from the locations list and print the next 100?'
                }
            />
            <div className='flex w-full items-center '>
                <div className='w-4/5 '>
                    <form
                        className='bg-white shadow-md rounded px-8 pt-6 pb-4 mb-4 m-12 border relative'
                        onSubmit={handleSubmit}
                    >
                        <input
                            type='text'
                            placeholder='Location Name'
                            value={locationName}
                            onChange={handleInputChange} // Update state when input changes
                            className='pr-10'
                        />
                        <MagnifyingGlassIcon
                            onClick={handleSubmit}
                            className='h-6 w-6 text-gray-700 hover:text-gray-900 absolute right-10 top-12 transform -translate-y-1/2'
                        />
                        <button type='submit' className='hidden'>
                            Submit
                        </button>{' '}
                        {/* Hidden submit button to enable submitting with Enter */}
                        {error ? <p className='text-red-500 text-md italic'>{error}</p> : <div className='h-2'></div>}
                    </form>
                    {showSuggestions && locationName && (
                        <div className='relative'>
                            <div className='absolute z-10 mt-2 bg-white border border-gray-300 rounded shadow-lg ml-12 -top-5'>
                                <List
                                    height={300} // Adjust based on your requirements
                                    itemCount={filteringLocations.length}
                                    itemSize={35} // Adjust based on your requirements
                                    width={200} // Adjust based on your requirements
                                >
                                    {({ index, style }) => {
                                        const location = filteringLocations[index];
                                        return (
                                            <div
                                                key={index}
                                                onClick={() => handleSuggestionClick(location)}
                                                className='cursor-pointer p-2 hover:bg-gray-200'
                                                style={style} // This is important
                                            >
                                                {location.Name}
                                            </div>
                                        );
                                    }}
                                </List>
                            </div>
                        </div>
                    )}
                </div>
                <div className='flex justify-center mx-24 w-1/2  h-16 space-x-4 '>
                    {/* <button
                        onClick={() => {
                            setFilteredLocations([]);
                            setLocationName('');
                        }}
                        className='bg-blue-primary hover:bg-blue-secondary text-white text-xl font-bold py-2 px-4 rounded'
                    >
                        Clear All
                    </button> */}
                    <button
                        onClick={() => {
                            setFilteredLocations([]);
                            setLocationName('');
                        }}
                        className='bg-blue-primary hover:bg-blue-secondary text-white text-xl font-bold py-2 px-4 rounded'
                    >
                        Show All
                    </button>
                    <button
                        onClick={handlePrintAll}
                        className='bg-blue-primary hover:bg-blue-secondary text-white text-xl font-bold py-2 px-4 rounded'
                    >
                        Print First 100
                    </button>
                </div>
            </div>
            {loading ? (
                <LoadingSpinner />
            ) : (
                <div className='w-full border mx-auto max-w-7xl mb-8'>
                    <LocationsTable
                        allLocations={allLocations}
                        filteredLocations={filteredLocations}
                        setFilteredLocations={setFilteredLocations}
                        loading={loading}
                        setLoading={setLoading}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                    />
                </div>
            )}
        </div>
    );
}
