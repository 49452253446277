import { defaultCameraHeightResolution, defaultCameraWidthResolution } from './data';

// Determine user camera height based on video stream
export const getCameraHeight = (stream) => {
    let {height} = stream?.getTracks()[0]?.getSettings();

    return height ?? defaultCameraHeightResolution;
}

// Determine user camera width based on video stream
export const getCameraWidth = (stream) => {
    let {width} = stream?.getTracks()[0]?.getSettings();

    return width ?? defaultCameraWidthResolution;
}