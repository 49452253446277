import React, { useState, useEffect, useRef } from 'react';
import config from '../../../utils/config';
import { useAuth } from '../../../wms/context/wms-context';
import { getAllScanners } from '../../../utils/commonAPICalls.js';
import Notification from '../../../components/Notification';
import logError from '../../../utils/errorUtil';

export default function ControlHeader({ status, setStatus }) {
    const ORIGIN = config.origin;
    const { currentUser, setCurrentUser } = useAuth();

    const [selectedMaid, setSelectedMaid] = useState('');
    const [selectedScanner, setSelectedScanner] = useState('');

    const [allMaids, setAllMaids] = useState();
    const [allScanners, setAllScanners] = useState([]);

    const [maidLines, setMaidLines] = useState();
    const [showMaidLines, setShowMaidLines] = useState(true);

    const [showNotification, setShowNotification] = useState(false);
    const [notificationDetails, setNotificationDetails] = useState({});

    const partFilterRef = useRef();

    // Ensure that the selected scanner updates even when changed from the modal
    useEffect(() => {
        if (localStorage.getItem('selectedScanner') !== null) {
            const savedScannerString = localStorage.getItem('selectedScanner');
            const savedScanner = savedScannerString && JSON.parse(savedScannerString);
            setSelectedScanner(savedScanner);
        }
    }, [currentUser])

    useEffect(() => {
        const savedScannerString = localStorage.getItem('selectedScanner');
        const savedScanner = savedScannerString && JSON.parse(savedScannerString);

        if (savedScanner && savedScanner.name) {
            setSelectedScanner(savedScanner);
            setAllScanners([savedScanner, { name: '', displayName: 'LOADING SCANNERS' }]);
            setStatus({ ...status, loading: false, receivingPanel: false });
            currentUser.selectedScanner = savedScanner;
        }

        if (!allScanners?.length) {
            getAllScanners(currentUser, setCurrentUser).then((data) => {
                if (data?.error) {
                    setStatus({ ...status, loading: false, receivingPanel: false, error: true, errorMessage: data.message });
                    return [];
                }
                if (data === 'Unauthorized') {
                    setNotificationDetails({
                        title: 'Error',

                        desc: 'You are not authorized to view this page.',
                        status: 'error',
                    });

                    setShowNotification(true);
                    setStatus({ ...status, loading: false, receivingPanel: false });
                    return;
                }

                setAllScanners(data);
            });
        }
    }, []);

    useEffect(() => {
        const savedMaidString = localStorage.getItem('selectedMaid');
        const savedMaid = savedMaidString && JSON.parse(savedMaidString);

        if (savedMaid && savedMaid.Id) {
            setSelectedMaid(savedMaid);
            setAllMaids([savedMaid, { Id: '', Name: 'LOADING MANIFESTS' }]);
            setStatus({ ...status, loading: false, receivingPanel: false });
            currentUser.selectedMaid = savedMaid;
        }
        if (!allMaids?.length) getAllMaids();
    }, []);

    useEffect(() => {
        if (selectedMaid && allMaids) {
            // find the maid in the allMaids array
            const foundMaid = allMaids.find((maid) => maid.Id === selectedMaid);
            if (foundMaid) {
                setSelectedMaid(foundMaid.Id);
            }
        }
    }, [allMaids, selectedMaid]);

    const handleMaidChange = (event) => {
        const value = event.target.value;
        setSelectedMaid(value);

        // Find the maid in the allMaids array to get both ID and Name
        const maidData = allMaids.find((maid) => maid.Id === value);
        if (maidData) {
            localStorage.setItem('selectedMaid', JSON.stringify(maidData));
            currentUser.selectedMaid = maidData;
            setSelectedMaid(maidData);
        }
    };

    const handleScannerChange = (event) => {
        const value = event.target.value;
        setSelectedScanner(value);

        // Find the scanner in the allScanners array to get both ID and Name
        const scannerData = allScanners.find((scanner) => scanner.name === value);
        if (scannerData) {
            localStorage.setItem(
                'selectedScanner',
                JSON.stringify({ displayName: scannerData.displayName, name: scannerData.name })
            );
            setSelectedScanner(scannerData);
            setCurrentUser({ ...currentUser, selectedScanner: scannerData });
        }
    };

    const handlePartFilter = () => {
        // check if canner and MAID are selected
        if (!selectedScanner || !selectedScanner.name || !selectedMaid || !selectedMaid.Id) {
            setNotificationDetails({
                title: 'Error',
                desc: 'You must select a scanner and a manifest before filtering by part number.',
                status: 'error',
            });
            setShowNotification(true);
            return;
        }

        setStatus({ ...status, loading: true, receivingPanel: false, receiveNewLinePanel: false });

        // remove any % ' or " from the part number

        partFilterRef.current.value = partFilterRef.current.value.replace(/[%'"]/g, '');

        const dataToSend = {
            manifestId: selectedMaid.Id,
            partNumber: partFilterRef.current.value,
            authentication: {
                userId: currentUser.authentication.userId,
                userEmail: currentUser.authentication.userEmail,
                userCompany: currentUser.authentication.userCompany || localStorage.getItem('company'),
            },
        };

        fetch(`${ORIGIN}/wms/receiving/get-manifest-lines`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataToSend),
        })
            .then((res) => {
                if (res.ok && res.headers.get('content-type')?.includes('application/json')) {
                    return res.json();
                } else {
                    return {
                        error: 'No Manifest Id',
                    };
                }
            })
            .then((data) => {
                if (data?.error === 'No Manifest Id') {
                    setNotificationDetails({
                        title: 'No Manifest ID',
                        desc: 'Please log out and log back in.',
                        status: 'error',
                    });
                    setShowNotification(true);
                    setStatus({ ...status, loading: false, receivingPanel: false });
                    return;
                }
                // if status 401 unauthorized, show notification and return
                if (data === 'Unauthorized') {
                    setNotificationDetails({
                        title: 'Error',
                        desc: 'You are not authorized to view this page.',
                        status: 'error',
                    });
                    setShowNotification(true);
                    setStatus({ ...status, loading: false, receivingPanel: false });
                    return;
                }

                setStatus({ ...status, loading: false, receivingPanel: false });
                setShowMaidLines(true);
                setMaidLines(data);
            })
            .catch((err) => {
                logError(err, 'handlePartFilter');
                setStatus({ ...status, loading: false, error: true });
            });
    };

    let getAllMaids = () => {
        const dataToSend = {
            authentication: {
                userId: currentUser.authentication.userId,
                userEmail: currentUser.authentication.userEmail,
                userCompany: currentUser.authentication.userCompany || localStorage.getItem('company'),
            },
        };
        fetch(`${ORIGIN}/wms/receiving/get-manifests`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataToSend),
        })
            .then((res) => {
                if (!res.ok) {
                    if (res.status === 500) {
                        // Process the response to get the error message
                        return res.json().then((errorData) => {
                            setStatus({
                                ...status,
                                loading: false,
                                error: true,
                                errorMessage: errorData.message || 'Internal Server Error',
                            });
                        });
                    }
                    // Handle other non-2xx responses if needed
                }
                if (res.ok && res.headers.get('content-type')?.includes('application/json')) {
                    return res.json();
                } else {
                    return {
                        error: 'Internal Server Error',
                    };
                }
            })
            .then((data) => {
                if (data?.error === 'Internal Server Error') {
                    setNotificationDetails({
                        title: 'Internal Server Error',
                        desc: 'Please log out and log back in.',
                        status: 'error',
                    });
                    setShowNotification(true);
                    setStatus({ ...status, loading: false, receivingPanel: false });
                    return;
                }
                setAllMaids(data);
            })
            .catch((err) => {
                logError(err, 'getAllMaids');
                return [];
            });
    };

    const handleMaidLineSelection = (line) => {
        setShowMaidLines(false);
        setStatus({ ...status, receivingPanel: true });
        currentUser.selectedMaidLine = line;
    };

    const openReceivingLine = () => {
        if (!selectedScanner || !selectedScanner.name || !selectedMaid || !selectedMaid.Id) {
            setNotificationDetails({
                title: 'Error',
                desc: 'You must select a scanner and a manifest.',
                status: 'error',
            });
            setShowNotification(true);
            return;
        }
        if (status.loading) return;
        setCurrentUser({ ...currentUser, selectedManifest: selectedMaid });
        setStatus({ ...status, receiveNewLinePanel: true, receivingPanel: false, actionPanel: false, loading: false });
        setShowMaidLines(false);
    };

    return (
        <>
            <Notification
                showSavedNotification={showNotification}
                setShowSavedNotification={setShowNotification}
                title={notificationDetails.title}
                desc={notificationDetails.desc}
                status={notificationDetails.status}
            />

            <div className='p-4 '>
                <div className='flex justify-between'>
                    <form className='flex flex-col'>
                        <label className='text-gray-700'>
                            Filter By Part #<span className='text-gray-400'>(BAC...)</span>
                        </label>
                        <div className='flex flex-col md:flex-row items-center space-x-4'>
                            <input
                                disabled={status.loading}
                                ref={partFilterRef}
                                type='text'
                                placeholder='Type in part number or leave blank'
                                className='p-2 border rounded w-72 disabled:opacity-50'
                            />
                            <button
                                type='submit'
                                disabled={status.loading || !currentUser.tableStructures}
                                onClick={(e) => {
                                    e.preventDefault();
                                    handlePartFilter();
                                }}
                                title={!currentUser.tableStructures ? 'Loading...' : ''}
                                className='p-2 border rounded w-24 bg-blue-primary text-white disabled:opacity-50 cursor-pointer'
                            >
                                Filter
                            </button>
                        </div>
                        {maidLines && showMaidLines && maidLines.manifestLines && !status.loading && (
                            <div className='relative '>
                                <div className='absolute z-10 mt-2 bg-white border border-gray-300 shadow-sm'>
                                    <table className='table-auto w-screen'>
                                        <thead>
                                            <tr className='bg-blue-primary text-white'>
                                                <th className='border px-2 py-2'>Name</th>
                                                <th className='border px-2 py-2'>Part Number</th>
                                                <th className='border px-2 py-2'>Status</th>
                                                <th className='border px-2 py-2'>Serial Number</th>
                                                <th className='border px-2 py-2'>Condition Code</th>
                                                <th className='border px-2 py-2'>Qty Man</th>
                                                <th className='border px-2 py-2'>Qty Rec</th>
                                                <th className='border px-2 py-2'>Qty Scrapped</th>
                                                <th className='border px-2 py-2'>Created Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {maidLines &&
                                                maidLines.manifestLines.map((line, index) => (
                                                    <tr
                                                        key={line.Id}
                                                        title={
                                                            !line.isValidLine
                                                                ? `Not a valid manifest line - missing fields ${line.missingFields}`
                                                                : ''
                                                        }
                                                        className={`cursor-pointer ${
                                                            !line.isValidLine ? 'bg-red-300' : 'hover:bg-gray-100'
                                                        }`}
                                                        onClick={() => {
                                                            if (line.isValidLine) handleMaidLineSelection(line);
                                                        }}
                                                    >
                                                        <td className='border px-4 py-2'>{line.Name}</td>
                                                        <td className='border px-4 py-2'>
                                                            {line.inscor__Part_Master__rName}
                                                        </td>
                                                        <td className='border px-4 py-2'>{line.inscor__Status__c}</td>
                                                        <td className='border px-4 py-2'>{line.inscor__Serial_Number__c}</td>
                                                        <td className='border px-4 py-2'>
                                                            {line.inscor__Condition_Code__rName}
                                                        </td>
                                                        <td className='border px-4 py-2'>
                                                            {line.inscor__Quantity_on_Manifest__c}
                                                        </td>
                                                        <td className='border px-4 py-2'>
                                                            {line.inscor__Quantity_Received__c}
                                                        </td>
                                                        <td className='border px-4 py-2'>
                                                            {line.inscor__Quantity_Scrapped__c}
                                                        </td>

                                                        {/* trim the date when you find a t */}
                                                        <td className='border px-4 py-2'>
                                                            {line.CreatedDate.split('T')[0]}
                                                        </td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        )}
                    </form>
                    <div className='flex flex-col md:flex-row md:space-x-4  md:space-y-0 space-y-4'>
                        <button
                            type='submit'
                            disabled={status.loading || !currentUser.tableStructures}
                            title={!currentUser.tableStructures ? 'Loading...' : ''}
                            onClick={() => {
                                openReceivingLine();
                            }}
                            className='p-2 border rounded w-48 bg-blue-primary text-white disabled:opacity-50 h-10 cursor-pointer'
                        >
                            Receive Inventory Line
                        </button>
                        <form className='flex flex-col'>
                            <select
                                disabled={status.loading}
                                className='p-2 border rounded w-48 disabled:opacity-50'
                                value={selectedMaid.Id || ''}
                                onChange={handleMaidChange}
                            >
                                <option value='' disabled>
                                    Select a Manifest
                                </option>
                                {allMaids &&
                                    allMaids.length &&
                                    !status.loading &&
                                    allMaids.map((maid) => {
                                        return (
                                            <option key={maid.Id} value={maid.Id}>
                                                {maid.Name}
                                            </option>
                                        );
                                    })}
                            </select>
                        </form>
                        <form className='flex flex-col'>
                            <select
                                disabled={status.loading}
                                className='p-2 border rounded w-48 disabled:opacity-50'
                                value={selectedScanner.name || ''}
                                onChange={handleScannerChange}
                            >
                                <option value='' disabled>
                                    Select a Scanner
                                </option>
                                {allScanners.length &&
                                    !status.loading &&
                                    allScanners.map((scanner) => {
                                        return (
                                            <option key={scanner.name} value={scanner.name}>
                                                {scanner.displayName}
                                            </option>
                                        );
                                    })}
                            </select>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}
