import { base64ToBlob } from '../../../../utils/commonAPICalls';
import { lineLoadingState, pdfLinkNameKey, pdfLoadingState, pdfTypes } from './data';
import { submitTracePhoto, updatePhotoData } from './fetchRequests';
import { PDFDocument } from 'pdf-lib';
const _ = require('lodash');

function updateInventoryLinePhotosStatus(photoNames, inventoryLine, status) {
    inventoryLine.photos = inventoryLine.photos.map((photoData) => {
        if (photoNames.includes(photoData.name)) {
            return {
                ...photoData,
                uploadStatus: status,
            };
        }

        return photoData;
    });
}

export const updateHeroImages = async (
    authData,
    updatedInventoryLines,
    photoUpdateErrors,
    photosChanged,
    updatePhotoStatusesForSubmission,
    isAccepted,
    releaseId
) => {
    // Loop through inventory lines and update hero image updates
    for (const inventoryLineData of updatedInventoryLines) {
        const photosChangedNames = photosChanged.map((data) => data.name);

        // Get photos that were previously hero images and send them
        const photosToSend = inventoryLineData.photos.filter((photoData) => {
            const isPreviouslyHero = photoData.name.includes(inventoryLineData.name) && photoData.isHero;
            const statusChanged = photosChangedNames.includes(photoData.name);
            const successfullyUploaded = photoData?.uploadStatus === true;

            if ((isPreviouslyHero || statusChanged) && !successfullyUploaded) {
                return photoData;
            }
        });
        // Handle updating submission board
        const photosSentNames = photosToSend.map((photo) => photo.name);

        try {
            const photosChangedResponse = await updatePhotoData(
                photosToSend,
                authData,
                inventoryLineData.name,
                isAccepted,
                releaseId,
                inventoryLineData.ownerCodeName
            );

            if (!photosChangedResponse?.success) {
                photoUpdateErrors.push(
                    photosChangedResponse?.message ?? `Error updating hero images for ${inventoryLineData.name}`
                );
            }

            // Updating individual statuses visually as well as persisting photo status on the inventory line
            updateInventoryLinePhotosStatus(photosSentNames, inventoryLineData, photosChangedResponse?.succes);
            updatePhotoStatusesForSubmission(photosSentNames, photosChangedResponse);
        } catch (error) {
            photoUpdateErrors.push(error.message);
            updateInventoryLinePhotosStatus(photosSentNames, inventoryLineData, false);
        }
    }
};

export const addImagesToInventoryLines = async (
    authData,
    updatedInventoryLines,
    photoAdditionErrors,
    updatePhotoStatusesForSubmission,
    photosAdded,
    isAccepted,
    releaseId
) => {
    // Loop through inventory lines and upload new photos
    for (const inventoryLineData of updatedInventoryLines) {
        try {
            const { ownerCodeName, name, id, productName } = inventoryLineData;
            const addedPhotoNames = photosAdded.map((photo) => photo.name);
            const addedPhotosToSend = inventoryLineData?.photos?.filter(
                (photoData) => !photoData.uploadStatus && addedPhotoNames.includes(photoData.name)
            );

            for (const photo of addedPhotosToSend) {
                try {
                    const photoBlob = base64ToBlob(photo.base64, 'image/png');
                    const inventoryData = { invLineId: id, invLineName: name, productName, ownerCodeName };
                    const response = await submitTracePhoto(
                        photoBlob,
                        photo.isHero,
                        authData,
                        inventoryData,
                        isAccepted,
                        releaseId
                    );

                    if (!response?.success) {
                        photoAdditionErrors.push(response?.message ?? `Error uploading photo ${photo?.name}`);
                    }

                    // Updating individual statuses visually as well as persisting photo status on the inventory line
                    updateInventoryLinePhotosStatus([photo.name], inventoryLineData, response?.success);
                    updatePhotoStatusesForSubmission([photo.name], response);
                } catch (error) {
                    updateInventoryLinePhotosStatus([photo.name], inventoryLineData, false);
                    updatePhotoStatusesForSubmission([photo.name], { success: false });
                }
            }
        } catch (error) {
            photoAdditionErrors.push(error.message);
            const currentInventoryLinePhotoNames = inventoryLineData?.photos.map((photo) => photo.name);
            updatePhotoStatusesForSubmission(currentInventoryLinePhotoNames, { success: false });
        }
    }
};

export const formatReleaseData = (response) => {
    const responseData = response.data;
    const inventoryLineInfo = responseData?.releaseLineData.map((data) => {
        return {
            aisle: data.aisle,
            comments: data.invComments,
            conditionCode: data.conditionCode,
            documentsAndPhotosLoaded: lineLoadingState.NOT_LOADED,
            heroPictureNames: data.heroPictureNames,
            id: data.invLineId,
            inventoryLink: data.linkToInvLine,
            keyword: data.keyword,
            loadingPdf: false,
            loadingBarcode: false,
            location: data.location,
            name: data.invLineName,
            ownerCodeName: data.ownerCodeName,
            pdfs: getPdfValuesFromCorrespondingLink(data),
            photos: [],
            productName: data.ProductName,
            productId: data.productId,
            quantity: data.quantity,
            serialNumber: data.serialNumber,
            shown: false,
            source: data.source,
            tagAgency: data.tagAgency,
            tags: data.tags,
            trace: data.trace,
            traceBinderOneClickLink: data.traceBinderOneClickLink,
            traceFullBinderLink: data.traceFullBinderLink,
            traceOwnerCodeLink: data.traceOwnerCodeLink,
            tracePartRecordLink: data.tracePartRecordLink,
            tracePicturesLink: data.tracePicturesLink,
            traceTagLink: data.traceTagLink,
            traceType: data.traceType,
        };
    });
    const orderTypeId = responseData?.releaseHeader?.salesOrder?.id ?? responseData?.releaseHeader?.repairOrder?.id ?? null;
    const orderTypeName =
        responseData?.releaseHeader?.salesOrder?.name ?? responseData?.releaseHeader?.repairOrder?.name ?? null;

    const orderedInventoryLines = _.orderBy(inventoryLineInfo, ['location', 'productName', 'aisle']);

    return {
        Id: responseData.releaseHeader?.Id,
        status: responseData.releaseHeader?.status,
        linkToRelease: responseData.releaseHeader?.linkToReleaseHeader,
        releaseComments: responseData?.releaseHeader?.commentsAndInstructions,
        releaseOrderData: {
            name: orderTypeName,
            id: orderTypeId,
        },
        inventoryLineInfo: orderedInventoryLines,
    };
};

export const getPdfValuesFromCorrespondingLink = (inventoryLine, updatedStatus = pdfLoadingState.LOADING) => {
    return Object.values(pdfTypes).map((type) => {
        if (inventoryLine[pdfLinkNameKey[type]]) {
            return {
                name: type,
                data: null,
                link: inventoryLine[pdfLinkNameKey[type]],
                status: updatedStatus,
            };
        }

        return {
            name: type,
            data: null,
            link: null,
            status: pdfLoadingState.NO_DATA,
        };
    });
};

export const formatDocumentsAndPhotosPayload = (inventoryLine) => {
    const { name, ownerCodeName, tracePicturesLink, traceOwnerCodeLink, tracePartRecordLink, traceTagLink } = inventoryLine;
    return {
        invLine: name,
        ownerCode: ownerCodeName,
        photos: tracePicturesLink,
        files: {
            traceOwnerCodeLink,
            tracePartRecordLink,
            traceTagLink,
        },
    };
};

export const addDocumentsAndPhotosToInventoryLine = (inventoryLine, data) => {
    const { documents, photos } = data;

    const pdfLinks = {
        [pdfTypes.TRACE_TAG]: inventoryLine.traceTagLink,
        [pdfTypes.PART_RECORD]: inventoryLine.tracePartRecordLink,
        [pdfTypes.OWNER_CODE]: inventoryLine.traceOwnerCodeLink,
        [pdfTypes.ONE_CLICK_BINDER]: inventoryLine.traceBinderOneClickLink,
    };

    return {
        ...inventoryLine,
        photos: photos?.map((photoData) => {
            return {
                name: photoData.name,
                base64: photoData.data,
                isHero: inventoryLine?.heroPictureNames?.includes(photoData.name) ? true : false,
            };
        }),
        pdfs: documents?.map((documentData) => {
            return {
                name: documentData.name,
                data: documentData.data,
                link: pdfLinks[documentData.name],
                status: documentData.data
                    ? pdfLoadingState.LOADED
                    : pdfLinks[documentData.name]
                    ? pdfLoadingState.LOADING_FAILED
                    : pdfLoadingState.NO_DATA,
            };
        }),
        documentsAndPhotosLoaded: lineLoadingState.LOADED,
    };
};

export const isValidRlsNumber = (rlsNumber) => {
    if (!rlsNumber.includes('rls-') && !rlsNumber.includes('RLS-')) {
        return false;
    }

    return true;
};

export const mergePdfPagesAndOpen = async (pdfData) => {
    const mergedPdf = await PDFDocument.create();

    for (const base64Data of pdfData) {
        const loadedPdf = await PDFDocument.load(base64Data);
        const copiedPages = await mergedPdf.copyPages(loadedPdf, loadedPdf.getPageIndices());
        copiedPages.forEach((page) => mergedPdf.addPage(page));
    }

    const mergedPdfFile = await mergedPdf.save();
    const blob = new Blob([mergedPdfFile], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(blob);
    window.open(fileURL);

    // Cleanup
    URL.revokeObjectURL(fileURL);
};

export const openPdf = async (pdfData) => {
    const pdfFile = await PDFDocument.load(pdfData);
    const savedPdf = await pdfFile.save();

    const blob = new Blob([savedPdf], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(blob);
    window.open(fileURL);

    // Cleanup
    URL.revokeObjectURL(fileURL);
};

export function formatReleaseComments(releaseComments) {
    return releaseComments.map((commentType) => {
        const commentArr = Object.entries(commentType);
        const commentKey = commentArr?.[0]?.[0];
        const commentValue = commentArr?.[0]?.[1];

        return {
            title: commentKey ? sanitizeCommentNames(commentKey) : '',
            value: commentValue ? commentValue : '',
        };
    });
}

export function sanitizeCommentNames(commentName) {
    commentName = commentName.replace(/([A-Z]+)/g, ' $1').replace(/([A-Z][a-z])/g, ' $1');
    // Convert from camelCase to Title Case for a multi-word comment name
    commentName = commentName.charAt(0).toUpperCase() + commentName.slice(1);
    return commentName;
}
