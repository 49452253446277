import config from '../../../../utils/config.js';
import logError from '../../../../utils/errorUtil.js';

export const fetchReleaseData = async (inputReleaseNumber, currentUser) => {
    const ORIGIN = config.origin;
    const dataToSend = {
        authentication: {
            userId: currentUser.authentication.userId,
            userEmail: currentUser.authentication.userEmail,
            userCompany: currentUser.authentication.userCompany || localStorage.getItem('company'),
        },
        inputReleaseNumber,
    };
    const response = await fetch(`${ORIGIN}/wms/trace/get-inv-data`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataToSend),
    });

    if (!response.ok) throw new Error('Failed to get inventory data');

    const responseData = await response.json();

    return responseData;
};

export const fetchDocumentsAndPhotos = async (currentUser, body) => {
    const ORIGIN = config.origin;
    const dataToSend = {
        authentication: {
            userId: currentUser.authentication.userId,
            userEmail: currentUser.authentication.userEmail,
            userCompany: currentUser.authentication.userCompany || localStorage.getItem('company'),
        },
        ...body,
    };
    const response = await fetch(`${ORIGIN}/wms/trace/get-inv-files`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataToSend),
    });

    if (!response.ok) throw new Error('Failed to get inventory documents and photos');

    const responseData = await response.json();

    return responseData;
};

export const fetchBinderData = async (traceBinderOneClickLink, currentUser) => {
    const ORIGIN = config.origin;
    const dataToSend = {
        authentication: {
            userId: currentUser.authentication.userId,
            userEmail: currentUser.authentication.userEmail,
            userCompany: currentUser.authentication.userCompany || localStorage.getItem('company'),
        },
        traceBinderOneClickLink,
    };
    const response = await fetch(`${ORIGIN}/wms/trace/get-trace-one-click`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataToSend),
    });

    if (!response.ok) throw new Error('Failed to get inventory data');

    const responseData = await response.json();

    return responseData;
};

export const submitReleaseData = async (releaseId, isAccepted, authData) => {
    const ORIGIN = config.origin;
    try {
        const dataToSend = {
            authentication: authData,
            releaseId,
            isAccepted,
        };

        const response = await fetch(`${ORIGIN}/wms/trace/submit-release-update`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataToSend),
        });

        if (!response.ok) throw new Error('Failed to get inventory data');

        const data = await response.json();

        return data;
    } catch (error) {
        logError(error, 'submitTracePhotos');
        return null;
    }
};

export const updatePhotoData = async (photosChanged, authData, invLineName, isAccepted, releaseId, ownerCode) => {
    const ORIGIN = config.origin;
    try {
        if (!photosChanged?.length) return { success: true };
        const url = `${ORIGIN}/wms/trace/submit-photos-changed`;

        // Remove the base64 data from the photos
        const photos = photosChanged.map((photo) => {
            return {
                name: photo.name,
                isHero: photo.isHero,
            };
        });

        const body = JSON.stringify({
            authentication: authData,
            photos,
            invLineName,
            isAccepted,
            releaseId,
            ownerCode,
        });

        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body,
        });

        if (!response.ok) throw new Error('Failed to update the photos to be modified in Trace tab.');

        const data = response.json();

        return data;
    } catch (error) {
        logError(error, 'updatePhotoData');
        return null;
    }
};

export const submitTracePhoto = async (photoBlob, isHero, authData, inventoryData, isAccepted, releaseId) => {
    const ORIGIN = config.origin;
    try {
        const { invLineId, invLineName, productName, ownerCodeName } = inventoryData;
        const url = `${ORIGIN}/wms/trace/submit-trace-photo`;

        // Create the formData and append the necessary data
        const formData = new FormData();
        formData.append('authentication', JSON.stringify(authData));
        formData.append('isHero', isHero);
        formData.append('invLineId', invLineId);
        formData.append('invLineName', invLineName);
        formData.append('ProductName', productName);
        formData.append('ownerCodeName', ownerCodeName);
        formData.append('photo', photoBlob, 'photo.png');
        formData.append('isAccepted', isAccepted);
        formData.append('releaseId', releaseId);

        const response = await fetch(url, {
            method: 'POST',
            body: formData,
        });

        if (!response.ok) throw new Error('Error sending Trace photo upload to server.');

        const data = await response.json();

        return data;
    } catch (error) {
        logError(error, 'submitTracePhotos');
        return null;
    }
};

export const sendChatterMessage = async (currentUser, body) => {
    try {
        const ORIGIN = config.origin;
        const dataToSend = {
            authentication: {
                userId: currentUser.authentication.userId,
                userEmail: currentUser.authentication.userEmail,
                userCompany: currentUser.authentication.userCompany || localStorage.getItem('company'),
            },
            ...body,
        };
        const response = await fetch(`${ORIGIN}/wms/trace/submit-chatter`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataToSend),
        });

        if (!response.ok) throw new Error('Failed to upload chatter message');

        const responseData = await response.json();

        return responseData;
    } catch (error) {
        logError(error, 'submitChatterMessage');
    }
};

export const printInventoryLineBarcode = async (currentUser, inventoryLine) => {
    try {
        const ORIGIN = config.origin;
        const dataToSend = {
            authentication: {
                userId: currentUser.authentication.userId,
                userEmail: currentUser.authentication.userEmail,
                userCompany: currentUser.authentication.userCompany || localStorage.getItem('company'),
            },
            inventory: [
                {
                    Id: inventoryLine.id,
                    Name: inventoryLine.name,
                },
            ],
        };

        const data = await fetch(`${ORIGIN}/wms/inventory/print-inventory`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataToSend),
        });

        if (!data) throw Error('Error fetching data');

        const blobData = data.blob();

        return blobData;
    } catch (error) {
        logError(error, 'printInventoryLineBarcode');
    }
};

export const printAllPickingLabels = async (currentUser, releaseNumber) => {
    try {
        const ORIGIN = config.origin;
        let dataToSend = {
            releaseNumber: releaseNumber,
            authentication: {
                userId: currentUser.authentication.userId,
                userEmail: currentUser.authentication.userEmail,
                userCompany: currentUser.authentication.userCompany || localStorage.getItem('company'),
            },
        };

        await fetch(`${ORIGIN}/wms/picking/get-picking-tags`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataToSend),
        })
            .then((res) => res.blob())
            .then((blob) => {
                // open in a new tab
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.target = '_blank';
                //name of the file
                a.name = 'order.pdf';
                a.click();
            });
    } catch (error) {
        logError(error, 'printAllPickingLabels');
    }
};