import React, { useState, useEffect, useRef } from 'react';
import config from '../../../utils/config';
import { useAuth } from '../../../wms/context/wms-context';
import Notification from '../../../components/Notification';

export default function ControlHeader({ status, setStatus }) {
    const ORIGIN = config.origin;
    const { currentUser } = useAuth();

    const [releaseNumber, setReleaseNumber] = useState('');

    const handleGeneratePickingTags = (e) => {
        e.preventDefault();
        console.log('Generate Picking Tags');

        let dataToSend = {
            releaseNumber: releaseNumber,
            authentication: {
                userId: currentUser.authentication.userId,
                userEmail: currentUser.authentication.userEmail,
                userCompany: currentUser.authentication.userCompany || localStorage.getItem('company'),
            },
        };

        fetch(`${ORIGIN}/wms/picking/get-picking-tags`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataToSend),
        })
            .then((res) => res.blob())
            .then((blob) => {
                // open in a new tab
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.target = '_blank';
                //name of the file
                a.name = 'order.pdf';
                a.click();
            })
            .catch((err) => console.log(err));
    };

    return (
        <>
            <div className='p-4 '>
                <div className='flex justify-between'>
                    <form className='flex flex-col'>
                        <label className='text-gray-700'>
                            Enter or Scan Release Number#<span className='text-gray-400'>(RLS-275547)</span>
                        </label>
                        <div className='flex flex-col md:flex-row items-center space-x-4'>
                            <input
                                type='text'
                                onChange={(e) => {
                                    setReleaseNumber(e.target.value);
                                }}
                                placeholder='Type in or Scan Release Number'
                                className='p-2 border rounded w-72 disabled:opacity-50'
                            />
                            <button
                                type='submit'
                                onClick={(e) => {
                                    handleGeneratePickingTags(e);
                                }}
                                className='p-2 border rounded w-48 bg-blue-primary text-white disabled:opacity-50 cursor-pointer'
                            >
                                Generate Picking Tags
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}
