import { CheckCircleIcon, ChevronRightIcon, LinkIcon, XMarkIcon } from '@heroicons/react/24/outline';
import React, { useContext, useEffect } from 'react';
import { useAuth } from '../../../context/wms-context';
import { InventoryLineItemDataHolder } from './InventoryLineItemDataHolder';
import { lineLoadingState, pdfLoadingState, pdfTypes } from '../utils/data';
import { TraceContext } from '../Context/TraceContextWrapper';
import logError from '../../../../utils/errorUtil';
import {
    addDocumentsAndPhotosToInventoryLine,
    formatDocumentsAndPhotosPayload,
    getPdfValuesFromCorrespondingLink,
    mergePdfPagesAndOpen,
} from '../utils/functions';
import { fetchBinderData, fetchDocumentsAndPhotos, printInventoryLineBarcode } from '../utils/fetchRequests';

export default function InventoryLineItem({
    inventoryLine,
    updateInventoryLine,
    manageShownInventoryLines,
    handlePhotoEnlarge,
    selectedInventoryName,
}) {
    const { updateNotificationState } = useContext(TraceContext);
    const { currentUser } = useAuth();

    const getInventoryLineDocumentsAndPhotos = async () => {
        try {
            updateInventoryLine(inventoryLine.name, {
                ...inventoryLine,
                documentsAndPhotosLoaded: lineLoadingState.LOADING,
            });

            const formattedPayload = formatDocumentsAndPhotosPayload(inventoryLine);

            const [binderData, inventoryData] = await Promise.all([
                getOneClickBinderData(),
                fetchDocumentsAndPhotos(currentUser, formattedPayload),
            ]);

            if (!inventoryData?.success) throw Error(inventoryData.message);

            inventoryData?.data?.documents?.push({
                name: pdfTypes.ONE_CLICK_BINDER,
                data: binderData,
            });

            const updatedInventoryLine = addDocumentsAndPhotosToInventoryLine(inventoryLine, inventoryData.data);

            updateInventoryLine(inventoryLine.name, updatedInventoryLine);
        } catch (error) {
            logError(error, 'getInventoryLineDocumentsAndPhotos');
            updateNotificationState({
                error: true,
                shown: true,
                title: 'Error Loading Documents and Photos',
                message: `There was an error retrieving documents and photos for ${inventoryLine.name}. Please refresh the page to try again and if this issue persists contact your manager.`,
            });

            const inventoryFallbackData = {
                photos: [],
                documents: getPdfValuesFromCorrespondingLink(inventoryLine, pdfLoadingState.LOADING_FAILED),
            };

            console.log(inventoryFallbackData);

            const updatedInventoryLine = addDocumentsAndPhotosToInventoryLine(inventoryLine, inventoryFallbackData);

            updateInventoryLine(inventoryLine.name, updatedInventoryLine);
        }
    };

    const getOneClickBinderData = async () => {
        if (!inventoryLine?.traceBinderOneClickLink) return null;

        try {
            const binderData = await fetchBinderData(inventoryLine.traceBinderOneClickLink, currentUser);

            if (!binderData.success) throw Error(binderData.message);

            return binderData.data;
        } catch (error) {
            return null;
        }
    };

    const mergeSelectedPdfsAndPrint = async (selectedPdfs) => {
        try {
            updateInventoryLine(inventoryLine.name, { ...inventoryLine, loadingPdf: true });
            const pdfDataToPrint = selectedPdfs
                .map((pdfData) => {
                    // Handle missing data state
                    if (pdfData?.status === pdfLoadingState.LOADING_FAILED) return null;

                    return pdfData.data;
                })
                .filter((data) => data !== null);

            // Open links for selected pdfs if there is no data
            for (const pdf of selectedPdfs) {
                if (pdf?.link && pdf.status === pdfLoadingState.LOADING_FAILED) window.open(pdf.link);
            }

            if (pdfDataToPrint?.length) await mergePdfPagesAndOpen(pdfDataToPrint);
        } catch (error) {
            logError(error, 'mergeSelectedPdfsAndPrint');
            updateNotificationState({
                error: true,
                shown: true,
                title: 'Error Merging PDFs',
                message: `There was an error merging the PDF data for ${inventoryLine.name} during the print process. Please try again and if this issue persists contact your manager.`,
            });
        }
        updateInventoryLine(inventoryLine.name, { ...inventoryLine, loadingPdf: false });
    };

    const printBarcode = async () => {
        try {
            updateInventoryLine(inventoryLine.name, { ...inventoryLine, loadingBarcode: true });
            const barcodeData = await printInventoryLineBarcode(currentUser, inventoryLine);
            const blob = new Blob([barcodeData], { type: 'application/pdf' });
            const blobURL = window.URL.createObjectURL(blob);
            window.open(blobURL, '_blank');
        } catch (error) {
            updateNotificationState(
                {
                    message: 'Please try again and if the issue persists please notify your manager.',
                    title: 'Error Printing Inventory',
                    shown: true,
                    error: true,
                },
                { error, function: 'handleReleaseSubmission' }
            );
        }
        updateInventoryLine(inventoryLine.name, { ...inventoryLine, loadingBarcode: false });
    };

    useEffect(() => {
        if (inventoryLine.shown && inventoryLine?.documentsAndPhotosLoaded === lineLoadingState.NOT_LOADED) {
            if (
                !inventoryLine.tracePicturesLink &&
                !inventoryLine.traceTagLink &&
                !inventoryLine.traceOwnerCodeLink &&
                !inventoryLine.traceBinderOneClickLink &&
                !inventoryLine.traceOwnerCodeLink
            ) {
                return;
            }
            getInventoryLineDocumentsAndPhotos();
        }
    }, [inventoryLine.shown]);

    return (
        <>
            <section
                className={
                    'w-full flex flex-row items-start justify-between h-fit px-5 py-5 hover:cursor-pointer first:rounded-t-md first:border-t-2 last:rounded-lg border-b-2 border-x-2 relative ' +
                    (selectedInventoryName === inventoryLine.name ? 'bg-gray-100' : ' hover:bg-gray-50 ')
                }
                onClick={() => manageShownInventoryLines(inventoryLine.name)}
            >
                <div className='w-fit flex flex-row items-center justify-center'>
                    <ChevronRightIcon className={'h-4 w-4 duration-75 mr-4 ' + (inventoryLine?.shown && 'rotate-90')} />
                    <a
                        href={inventoryLine.inventoryLink}
                        target='_blank'
                        rel='noopener noreferrer'
                        className='w-full flex flex-row items-center justify-center group text-lg hover:text-blue-secondary duration-100'
                        onClick={(e) => e.stopPropagation()}
                    >
                        {inventoryLine.name}
                        <LinkIcon className='h-4 w-4 ml-1 group-hover:text-blue-secondary duration-100' />
                    </a>
                </div>
                <div className='flex flex-row items-center gap-4 relative'>
                    {inventoryLine.documentsAndPhotosLoaded === lineLoadingState.LOADING ? (
                        <div className='w-5 h-5 border-b-[2px] border-e-[2px] border-gray-500 rounded-full animate-spin out' />
                    ) : null}
                </div>
                <AcceptedStatusIndicator acceptedStatus={inventoryLine.isAccepted} />
            </section>
            <InventoryLineItemDataHolder
                inventoryLine={inventoryLine}
                handlePhotoEnlarge={handlePhotoEnlarge}
                updateInventoryLine={updateInventoryLine}
                mergeSelectedPdfsAndPrint={mergeSelectedPdfsAndPrint}
                printBarcode={printBarcode}
            />
        </>
    );
}

function AcceptedStatusIndicator({ acceptedStatus }) {
    if (acceptedStatus == null || acceptedStatus == undefined) return null;

    if (acceptedStatus) {
        return (
            <CheckCircleIcon
                className={'hover:cursor-default absolute w-10 h-10 top-4 -right-14 rounded-full bg-green-500 text-white '}
                onClick={(e) => e.stopPropagation()}
            />
        );
    }

    if (!acceptedStatus) {
        return (
            <XMarkIcon
                className={'hover:cursor-default absolute w-10 h-10 top-4 -right-14 rounded-full bg-red-500 text-white '}
                onClick={(e) => e.stopPropagation()}
            />
        );
    }
}
