import React, { useState } from 'react';
import ActionPanel from '../components/receiving/ActionPanelWorkOrder';

export default function WMSWorkOrder() {
    const [workOrderName, setWorkOrderName] = useState('');
    const [actionPanel, setActionPanel] = useState(false);

    const closeActionPanel = () => {
        setActionPanel(false);
        setWorkOrderName('');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        setActionPanel(true);
    };

    return (
        <>
            {actionPanel && (
                <ActionPanel
                    status={actionPanel}
                    setStatus={setActionPanel}
                    closeActionPanel={closeActionPanel}
                    canClose={true}
                    workOrderName={workOrderName}
                />
            )}

            <div className='flex h-[calc(100vh-64px)] items-center justify-center'>
                {
                    <form onSubmit={handleSubmit} className='flex flex-col items-center'>
                        <label htmlFor='workOrderNumber' className='mb-2 text-lg'>
                            AC Work Order Number
                        </label>
                        <input
                            id='workOrderNumber'
                            type='text'
                            value={workOrderName}
                            onChange={(e) => setWorkOrderName(e.target.value?.toUpperCase())}
                            className='p-2 border border-gray-300 rounded'
                        />

                        <button
                            disabled={workOrderName.length < 1}
                            type='submit'
                            className='mt-4 px-4 py-2 bg-blue-primary text-white rounded'>
                            Submit
                        </button>
                    </form>
                }
            </div>
        </>
    );
}
