import React, { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../portal/context/auth-context';
import { CheckUserData } from './commonAPICalls';

export default function PrivateRoute({ children }) {
    const location = useLocation();
    const { currentUser, updateCurrentUser } = useAuth(); // extract updateCurrentUser here

    useEffect(() => {
        let now = Date.now();
        const userDate = new Date(localStorage.getItem('date'));

        if (currentUser && now - userDate > 21600000) {
            CheckUserData(currentUser, updateCurrentUser); // Pass them as arguments
        }
    }, [location.pathname, currentUser]); // added currentUser to the dependency array

    return currentUser ? children : <Navigate to='/login' />;
}
