import React from 'react';

export default function LoadingSpinner({ className }) {
    return (
        <div role='status'>
            <div className={`flex items-center justify-center ${className}`}>
                <div className='w-36 h-36 border-b-4 border-gray-500 rounded-full animate-spin out'></div>
            </div>
        </div>
    );
}

LoadingSpinner.defaultProps = {
    className: 'mt-24',
};
